import React, { FC, useState } from "react";
import { NavLink, Route, Switch, useRouteMatch } from "react-router-dom";
import { startOfWeek, endOfWeek, addDays } from "date-fns";
import { ScreenTitle } from "context/ScreenTitle";
import { FAIcon } from "components/FAIcon";
import { TimeRange } from "./model";
import { DateRangeDropdown } from "./DateRangeDropdown";
import { ExportsWidget } from "./ExportsWidget";
import { AppealVolume } from "./reports/AppealVolume";
import { SLACompliance } from "./reports/SLACompliance";
import { ActivityInventory } from "./reports/ActivityInventory";
import { AnalyticsProvider } from "./AnalyticsContext";
import { Container } from "components/Container";
import { UMAutoAssignmentsOverTime } from "./reports/UMAutoAssignmentsOverTime";
import { UMFailedAssignmentsOverTime } from "./reports/UMFailedAssignmentsOverTime";
import { UMManualAssignmentsOverTime } from "./reports/UMManualAssignmentsOverTime";
import { UMFailedAssignmentsByFilters } from "./reports/UMFailedAssignmentsByFilters";

enum UmAnalytics {
  AutoAssignmentsOverTime = "um_auto_assignments_over_time",
  FailedAssignmentsOverTime = "um_failed_assignments_over_time",
  FailedAssignmentsByFilters = "um_failed_assignments_by_filters",
  ManualAssignmentsOverTime = "um_manual_assignments_over_time",
}

enum PostDecisionAnalytics {
  // NB: Report Request #1 from Mark's deck (2/2)
  SLACompliance = "sla_compliance",
  // NB: Report Request #2 from Mark's deck
  ActivityInventory = "activity_inventory",
  // NB: Report Request #3 from Mark's deck
  Production = "production",
  AppealVolume = "appeal_volume",
  AppealsByHealthPlan = "appeals_by_health_plan",
  InternalUserProduction = "internal_user_production",
  AppealOutcomes = "appeal_outcomes",
}

const startOfLastWeek = startOfWeek(addDays(new Date(), -7));
const endOfLastWeek = endOfWeek(addDays(new Date(), -7));

interface AnalyticsScreenProps { }

export const AnalyticsScreen: FC<AnalyticsScreenProps> = () => {
  const [timeRange, setTimeRange] = useState<TimeRange>({
    start: startOfLastWeek,
    finish: endOfLastWeek,
  });

  const match = useRouteMatch();

  return (
    <>
      <ScreenTitle title="Analytics" />
      <Container className="_AnalyticsScreen flex text-left">
        <div className="_sidebar flex-1 max-w-xs px-4">
          <div className="_date-range pt-8">
            <h3 className="my-4 font-semibold text-gray-600">Date Range</h3>
            <DateRangeDropdown value={timeRange} onChange={setTimeRange} />
          </div>

          <div className="_report-nav pt-8">
            <h3 className="my-4 font-semibold text-gray-600">Views</h3>
            <div className="p-2 bg-white border rounded-lg">
              {/*

              MARK: UM Requests

              */}
              <h4 className="p-4 text-sm font-bold text-gray-700">UM Requests</h4>
              <NavLink
                to={`/ip/reports/${UmAnalytics.AutoAssignmentsOverTime}`}
                className="transition-fast hover:translate-r-2px hover:text-gray-900 hover:bg-blue-200 relative block px-2 py-1 text-sm font-medium text-gray-600 transition-colors rounded-lg"
                activeClassName="text-blue-700 bg-blue-200"
              >
                <span className="pl-2 pr-3">
                  <FAIcon icon="chart-line" />
                </span>
                Auto-Assignments Over Time
              </NavLink>

              <NavLink
                to={`/ip/reports/${UmAnalytics.FailedAssignmentsOverTime}`}
                className="transition-fast hover:translate-r-2px hover:text-gray-900 hover:bg-blue-200 relative block px-2 py-1 mt-1 text-sm font-medium text-gray-600 transition-colors rounded-lg"
                activeClassName="text-blue-700 bg-blue-200"
              >
                <span className="pl-2 pr-3">
                  <FAIcon icon="chart-line" />
                </span>
                Failed Assignments Over Time
              </NavLink>

              <NavLink
                to={`/ip/reports/${UmAnalytics.FailedAssignmentsByFilters}`}
                className="transition-fast hover:translate-r-2px hover:text-gray-900 hover:bg-blue-200 relative block px-2 py-1 mt-1 text-sm font-medium text-gray-600 transition-colors rounded-lg"
                activeClassName="text-blue-700 bg-blue-200"
              >
                <span className="pl-2 pr-3">
                  <FAIcon icon="chart-line" />
                </span>
                Failed Assignments By Filters
              </NavLink>

              <NavLink
                to={`/ip/reports/${UmAnalytics.ManualAssignmentsOverTime}`}
                className="transition-fast hover:translate-r-2px hover:text-gray-900 hover:bg-blue-200 relative block px-2 py-1 mt-1 text-sm font-medium text-gray-600 transition-colors rounded-lg"
                activeClassName="text-blue-700 bg-blue-200"
              >
                <span className="pl-2 pr-3">
                  <FAIcon icon="chart-line" />
                </span>
                Manual Assignments Over Time
              </NavLink>

              {/*

              MARK: Post-Decision Requests

              */}
              {/* <h4 className="p-4 pt-8 text-sm font-bold text-gray-700">Post-Decision Requests</h4>

              <NavLink
                to={`/ip/reports/${PostDecisionAnalytics.SLACompliance}`}
                className="transition-fast hover:translate-r-2px hover:text-gray-900 hover:bg-blue-200 relative block px-2 py-1 text-sm font-medium text-gray-600 transition-colors rounded-lg"
                activeClassName="text-blue-700 bg-blue-200"
              >
                <span className="pl-2 pr-3">
                  <FAIcon icon="chart-bar" />
                </span>
                SLA Compliance
              </NavLink>

              <NavLink
                to={`/ip/reports/${PostDecisionAnalytics.ActivityInventory}`}
                className="transition-fast hover:translate-r-2px hover:text-gray-900 hover:bg-blue-200 relative block px-2 py-1 mt-1 text-sm font-medium text-gray-600 transition-colors rounded-lg"
                activeClassName="text-blue-700 bg-blue-200"
              >
                <span className="pl-2 pr-3">
                  <FAIcon icon="chart-bar" />
                </span>
                Activity Inventory
              </NavLink>

              <NavLink
                to={`/ip/reports/${PostDecisionAnalytics.AppealVolume}`}
                className="transition-fast hover:translate-r-2px hover:text-gray-900 hover:bg-blue-200 relative block px-2 py-1 mt-1 text-sm font-medium text-gray-600 transition-colors rounded-lg"
                activeClassName="text-blue-700 bg-blue-200"
              >
                <span className="pl-2 pr-3">
                  <FAIcon icon="chart-line" />
                </span>
                Appeal Volume
              </NavLink>

              <NavLink
                to={`/ip/reports/${PostDecisionAnalytics.AppealsByHealthPlan}`}
                className="transition-fast hover:translate-r-2px hover:text-gray-900 hover:bg-blue-200 relative block px-2 py-1 mt-1 text-sm font-medium text-gray-600 transition-colors rounded-lg"
                activeClassName="text-blue-700 bg-blue-200"
              >
                <span className="pl-2 pr-3">
                  <FAIcon icon="chart-bar" />
                </span>
                Requests by Health Plan
              </NavLink>

              <NavLink
                to={`/ip/reports/${PostDecisionAnalytics.InternalUserProduction}`}
                className="transition-fast hover:translate-r-2px hover:text-gray-900 hover:bg-blue-200 relative block px-2 py-1 mt-1 text-sm font-medium text-gray-600 transition-colors rounded-lg"
                activeClassName="text-blue-700 bg-blue-200"
              >
                <span className="pl-2 pr-3">
                  <FAIcon icon="chart-bar" />
                </span>
                Internal User Production
              </NavLink>

              <NavLink
                to={`/ip/reports/${PostDecisionAnalytics.AppealOutcomes}`}
                className="transition-fast hover:translate-r-2px hover:text-gray-900 hover:bg-blue-200 relative block px-2 py-1 mt-1 text-sm font-medium text-gray-600 transition-colors rounded-lg"
                activeClassName="text-blue-700 bg-blue-200"
              >
                <span className="pl-2 pr-3">
                  <FAIcon icon="chart-bar" />
                </span>
                Request Outcomes
              </NavLink> */}
            </div>
          </div>

          <div className="_exports pt-8">
            <h3 className="my-4 font-semibold text-gray-600">Exports</h3>
            <ExportsWidget />
          </div>
        </div>
        <div className="flex-1 p-8">
          <AnalyticsProvider timeRange={timeRange}>
            <Switch>
              {/* UM Analytics */}
              <Route path={`${match.path}/${UmAnalytics.AutoAssignmentsOverTime}`}>
                <UMAutoAssignmentsOverTime />
              </Route>
              <Route path={`${match.path}/${UmAnalytics.FailedAssignmentsOverTime}`}>
                <UMFailedAssignmentsOverTime />
              </Route>
              <Route path={`${match.path}/${UmAnalytics.FailedAssignmentsByFilters}`}>
                <UMFailedAssignmentsByFilters />
              </Route>
              <Route path={`${match.path}/${UmAnalytics.ManualAssignmentsOverTime}`}>
                <UMManualAssignmentsOverTime />
              </Route>

              {/* Post-Decision Analytics */}
              <Route path={`${match.path}/${PostDecisionAnalytics.SLACompliance}`}>
                <SLACompliance />
              </Route>
              <Route path={`${match.path}/${PostDecisionAnalytics.ActivityInventory}`}>
                <ActivityInventory />
              </Route>
              <Route path={`${match.path}/${PostDecisionAnalytics.AppealVolume}`}>
                <AppealVolume />
              </Route>
            </Switch>
          </AnalyticsProvider>
        </div>
      </Container>
    </>
  );
};
