import { lightFormat } from "date-fns";
import { gql, useQuery } from "@apollo/client";
import { CircleIcon } from "components/CircleIcon";
import { useAnalytics } from "../../AnalyticsContext";
import { NoResults } from "components/NoResults";
import { HorizontalBarChart } from "../../charts/HorizontalBarChart";
import { Spinner } from "components/Spinner";
import { Button } from "components/Button";

const UM_FAILED_ASSIGNMENTS_BY_FILTERS = gql`
  query UmFailedAssignmentsByFilters($dateRange: InclusiveDateRangeInput!) {
    analyticsUmNoMdsFailedAssignmentsByFilters(dateRange: $dateRange) {
      timeRange {
        start
        finish
      }
      data {
        group
        count
      }
    }
  }
`;

interface Data {
  analyticsUmNoMdsFailedAssignmentsByFilters: {
    timeRange: {
      start: string;
      finish: string;
    };
    data: Datum[];
  }
}

interface Datum {
  group: string;
  count: number;
}

function transform(data: Datum[]) {
  return data.map((d) => ({
    Group: d.group,
    Count: d.count,
  }));
}

export function UMFailedAssignmentsByFilters() {
  const { timeRange } = useAnalytics()
  const dateRange = {
    start: lightFormat(timeRange.start, "yyyy-MM-dd"),
    finish: lightFormat(timeRange.finish, "yyyy-MM-dd"),
  }

  const { data, loading, error, refetch } = useQuery<Data>(UM_FAILED_ASSIGNMENTS_BY_FILTERS, {
    variables: { dateRange },
  });

  return (
    <div className="_UMFailedAssignmentsByFilters m-6">
      <div className="p-4 bg-white rounded-lg shadow-lg">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <CircleIcon icon="chart-line" />
            <h4 className="ml-3 text-xl font-semibold text-gray-800">
              UM Failed Assignments By Modality and State
            </h4>
          </div>
          {/* <Button
            type="button"
            kind="tertiary"
            className="border"
            onClick={() => setActiveModal("EXPORT_WEBFORM_SUBMISSION_VOLUME")}
          >
            Export CSV{" "}
            <span className="ml-2">
              <FAIcon icon="file-download" />
            </span>
          </Button> */}
        </div>
        <div className="section pt-8">
          <p className="pt-2 pb-6">This chart shows the failed assignments where <b>no providers were available for the applied filters within the search time window</b>, grouped by the modality and state of the provider search filter.</p>
          <div className="pt-2">
            {loading ? (
              <div className="p-8 text-center">
                <Spinner />
              </div>
            ) : error || !data?.analyticsUmNoMdsFailedAssignmentsByFilters ? (
              <div>
                <p className="py-4 text-center">Failed to Load</p>
                <Button type="button" onClick={refetch}>
                  Retry
                </Button>
              </div>
            ) : data.analyticsUmNoMdsFailedAssignmentsByFilters.data.length === 0 ? (
              <NoResults
                icon="chart-bar"
                text="No data for selected date range"
              />
            ) : (
              <div style={{ textAlign: "left", padding: "2rem" }}>
                <HorizontalBarChart
                  data={transform(data.analyticsUmNoMdsFailedAssignmentsByFilters.data)}
                  bars={[
                    {
                      dataKey: "Count",
                      fill: "#1f77b4",
                    },
                  ]}
                  yValKey="Group"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
