import { CSSProperties, FC } from "react";
import {
  ResponsiveContainer,
  BarChart as RCBarChart,
  Bar as RCBar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

type Bar = {
  dataKey: string;
  fill: string;
  stackId?: string;
};

type HorizontalBarChartProps = {
  data: { [x in string]: any }[];
  bars: Bar[];
  style?: CSSProperties;

  /**
   * e.g "providerName"
   */
  yValKey: string;
};

export const HorizontalBarChart: FC<HorizontalBarChartProps> = ({
  data,
  bars,
  yValKey,
  style = {},
}) => {
  return (
    <div className="charts" style={{ height: heightForData(data), ...style }}>
      <ResponsiveContainer>
        <RCBarChart
          data={data}
          margin={{ top: 5, right: 30, left: 120, bottom: 5 }}
          layout="vertical"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" />
          <YAxis type="category" dataKey={yValKey} />
          <Tooltip />
          <Legend />
          {bars.map((bar) => (
            <RCBar
              key={bar.dataKey}
              stackId={bar.stackId || "a"}
              dataKey={bar.dataKey}
              fill={bar.fill}
            />
          ))}
        </RCBarChart>
      </ResponsiveContainer>
    </div>
  );
};

function heightForData(data: any[]): number {
  return Math.max(data.length * 30 || 400, 400);
}
